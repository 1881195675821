/* Для слайдера используется tiny-slider https://github.com/ganlanyuan/tiny-slider */
import { tns as tinySlider } from '../../../node_modules/tiny-slider/src/tiny-slider';

window.tinySlider = tinySlider;

const setHeight = (selector) => {
    let maxHeight = 0;
    const tnsItems = document.querySelectorAll(selector);
    tnsItems.forEach((item) => {
        let h = item.getBoundingClientRect().height;
        if (h > maxHeight) {
            maxHeight = h;
        }
    });

    tnsItems.forEach((item) => {
        item.style.height = `${maxHeight}px`;
    });
};

const sliders = () => {
    /* Настройка слайдера https://github.com/ganlanyuan/tiny-slider#options */
    const viewedItem = document.getElementById('viewed-item');
    const recommendedItem = document.getElementById('recommended-item');
    const likedItem = document.getElementById('liked-item');
    const itemMainImage = document.getElementById('item-main-image');
    /*const itemMainImageThumbnails = document.getElementById(
        '#item-main-image-thumbnails'
    );*/
    const itemMainImageMobile = document.getElementById('item-main-image-m');
    const itemMainImageThumbnailsMobile = document.getElementById('item-main-image-thumbnails-m');

    const compareItems = document.getElementById('compare-items');
    const compareListItems = document.getElementById('compare-list-items');
    const newItems = document.getElementById('new-items');
    const bannerItems = document.getElementById('banner-items');
    const bannerWrapper = document.getElementById('banner-wrapper');
    const product_of_week_items = document.getElementById('product_of_week-items');
    const awaitItems = document.getElementById('await-items');
    const buyNowItems = document.getElementById('buy-now-items');
    const expectedItems = document.getElementById('expected-items');
    const favorites = document.getElementById('favorites');
    const seenItems = document.getElementById('seen-items');
    const topBanner = document.getElementById('top-banner');
    const stories = document.getElementById('stories');
    /*const storiesModal = document.getElementById('stories-modal');*/

    const hiddenSliders = document.querySelectorAll('.hide-slider');
    hiddenSliders.forEach((item) => {
        item.parentElement.classList.remove('h-90');
        item.classList.remove('hide-slider');
    });

    if (topBanner) {
        tinySlider({
            container: topBanner,
            controlsContainer: '#top-banner-control',
            items: 1,
            autoplay: true,
            autoplayButtonOutput: false,
            nav: false,
            lazyload: true,
        });
    }

    if (seenItems) {
        tinySlider({
            container: seenItems,
            controlsContainer: '#seen-items-control',
            items: 4,
        });

        setHeight('#seen-items .tns-item');
    }

    if (favorites) {
        tinySlider({
            container: favorites,
            controlsContainer: '#favorites-control',
            items: 4,
        });

        setHeight('#favorites .tns-item');
    }

    if (expectedItems) {
        tinySlider({
            container: expectedItems,
            controlsContainer: '#expected-items-control',
            items: 4,
        });

        setHeight('#expected-items .tns-item');
    }

    // if (itemMainImageThumbnails) {
    //     tinySlider({
    //         axis: 'vertical',
    //         container: '#item-main-image-thumbnails',
    //         fixedWidth: 38,
    //         gutter: 8,
    //         controls: false,
    //     });
    // }

    // if (itemMainImageThumbnailsMobile) {
    //     tinySlider({
    //         container: '#item-main-image-thumbnails-m',
    //         fixedWidth: 38,
    //         gutter: 8,
    //         controls: false,
    //     });
    // }

    if (itemMainImage) {
        tinySlider({
            axis: 'vertical',
            container: '#item-main-image',
            navContainer: '#item-main-image-thumbnails',
            navAsThumbnails: true,
            items: 1,
            controls: false,
            loop: false,
        });
    }

    if (itemMainImageMobile && itemMainImageThumbnailsMobile) {
        tinySlider({
            container: '#item-main-image-m',
            // navContainer: '#item-main-image-thumbnails-m',
            navAsThumbnails: false,
            items: 1,
            controls: false,
        });
    } else if (itemMainImageMobile && !itemMainImageThumbnailsMobile) {
        tinySlider({
            container: '#item-main-image-m',
            items: 1,
            controls: false,
        });
    }

    if (stories) {
        tinySlider({
            container: stories,
            fixedWidth: 115,
            controlsContainer: '#stories-control',
            gutter: 16,
            loop: false,
        });
    }

    if (compareListItems) {
        tinySlider({
            container: compareListItems,
            items: 2,
            controlsContainer: '#compare-list-items-control',
            responsive: {
                1024: {
                    items: 5,
                    fixedWidth: 198,
                },
            },
        });

        setHeight('#compare-list-items .compare-header');
    }

    if (compareItems) {
        const compareSlider = tinySlider({
            container: compareItems,
            loop: false,
            fixedWidth: 150,
            controlsContainer: '#compare-items-control',
            responsive: {
                640: {
                    fixedWidth: 230,
                },
                1024: {
                    fixedWidth: 198,
                },
            },
        });

        // setHeight('#compare-items .compare-header');

        setTimeout(() => {
            const i = document.querySelector('.hide-items');
            if (i) {
                i.classList.remove('hide-items');
                i.classList.add('flex');
            }
        }, 1000);
    }

    if (newItems) {
        tinySlider({
            container: newItems,
            items: 2,
            controlsContainer: '#new-items-control',
            lazyload: true,
            responsive: {
                1024: {
                    items: 5,
                },
            },
        });

        setHeight('#new-items .tns-item');
    }

    if (bannerItems) {
        let banner = tinySlider({
            container: bannerItems,
            items: 1,
            autoplay: true,
            autoplayTimeout: 4000,
            speed: 800,
            autoplayButtonOutput: false,
            controlsContainer: '#banner-items-control',
            lazyload: true,
        });
        setHeight('#banner-items .tns-item');
        bannerItems.addEventListener('touchend', (e) => {
            banner.pause();
        });
        window.addEventListener('load', () => {
            document.querySelector('#banner-items').style.transitionDelay = '0.01s';
        })
    }


    if (product_of_week_items) {
        tinySlider({
            container: product_of_week_items,
            items: 2,
            controlsContainer: '#product_of_week-items-control',
            lazyload: true,
            responsive: {
                1024: {
                    items: 5,
                },
            },
        });

        setHeight('#product_of_week-items .tns-item');
    }

    if (buyNowItems) {
        tinySlider({
            container: buyNowItems,
            items: 2,
            controlsContainer: '#buy-now-items-control',
            lazyload: true,
            responsive: {
                1024: {
                    items: 5,
                },
            },
        });

        setHeight('#buy-now-items .tns-item');
    }

    if (awaitItems) {
        tinySlider({
            container: awaitItems,
            items: 2,
            controlsContainer: '#await-items-control',
            lazyload: true,
            responsive: {
                1024: {
                    items: 5,
                },
            },
        });

        setHeight('#await-items .tns-item');
    }

    if (viewedItem) {
        tinySlider({
            container: '#viewed-item',
            gutter: 16,
            fixedWidth: false,
            items: 2,
            nav: false,
            controlsContainer: '#viewed-item-control',
            responsive: {
                768: {
                    gutter: 27,
                    fixedWidth: 280,
                    items: 3,
                },
            },
        });

        setTimeout(() => {
            setHeight('#viewed-item .tns-item');
        }, 100);
    }

    if (recommendedItem) {
        tinySlider({
            container: '#recommended-item',
            gutter: 16,
            fixedWidth: false,
            items: 2,
            controlsContainer: '#recommended-item-control',
            nav: false,
            responsive: {
                768: {
                    gutter: 27,
                    fixedWidth: 280,
                    items: 3,
                },
            },
        });

        setHeight('#recommended-item .tns-item');
    }

    if (likedItem) {
        tinySlider({
            container: '#liked-item',
            gutter: 16,
            fixedWidth: false,
            items: 2,
            nav: false,
            controlsContainer: '#liked-item-control',
            responsive: {
                768: {
                    gutter: 27,
                    fixedWidth: 280,
                    items: 3,
                },
            },
        });

        setTimeout(() => {
            setHeight('#liked-item .tns-item');
        })
    }
};

export default sliders();
